<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <!-- <BaseHeader :title="$route.params.status"></BaseHeader> -->

      <div class="t-page-container   ">
        <BaseAsidebar></BaseAsidebar>
        <div v-if="orders && orders.length > 0" class="t-page-fluidcol">
          <BaseOrderitem :orders="orders" :filters="filters" ></BaseOrderitem>
          <div class="card-footer order-list d-flex align-items-center justify-content-between " v-if="orders.length > 0">
          <div class="row">
            <div class="col-12 col-sm-8">
              <div
                v-if="isEditable && bulkActionIds.length > 0"
                class="d-flex flex-row align-items-center"
              >
                <!-- <input  type="checkbox" style="width: 22px !important" checked />  -->
                <span
                  class="badge"
                  style="
                    height: 22px;
                    text-align: center;
                    vertical-align: middle !important;
                    border: 1px solid rgb(32 150 243);
                    color: rgb(32 150 243);
                    background: rgb(32 150 243 / 21%);
                    width: 22px;
                    padding: 3px;
                  "
                >
                  <span class="icon-check"></span>
                </span>
                <label class="my-1 ml-1"
                  >{{ bulkActionIds.length }} Orders Selected</label
                >

                <button
                  @click="bulkAction('pay')"
                  class="btn btn-custom-pay px-3 ml-2"
                >
                  Pay
                </button>

                
              </div>
            </div>
          </div>
          <br/>

          <p class="text-left">
            From {{ filters.from || "" }} to {{ filters.to || "" }} of
            {{ filters.total || "" }} orders
          </p>
          <ul class="pagination pagination-flat align-self-center">
            <li class="page-item" v-if="filters.prev_page_url">
              <a class="page-link-success " @click="navigate(filters.prev_page_url)"
                >← &nbsp; Prev</a
              >
            </li>
            <li class="page-item  active">
              <a class="page-link btn-custom-page ">On Page {{ filters.current_page || "" }}</a>
            </li>
            <li class="page-item" v-if="filters.next_page_url">
              <a class="page-link-success" @click="navigate(filters.next_page_url)"
                >Next &nbsp; →</a
              >
            </li>
          </ul>
        </div>
        </div>
        
        <div v-else class="order-list container orders__header header-without-form d-flex  flex-column align-items-center justify-content-center">
            <h4 class="order-info-mob-txt text-center"> No {{$route.params.status}} orders !</h4>
              <div class="custom_imageEmpty"></div>

            <router-link
            :to="{ name: 'PlaceOrder' }"
            class="btn px-3 py-2 btn-outline-primary"
            >Order Now</router-link>
            <!-- <div class="custom_imageEmpty"></div> -->
            
        </div>
        

        

      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "OrderListing",
  computed: {
    ...mapState("orders", ["orders", "filters"]),
    isEditable() {
      if (this.$route.params.status == "unpaid") {
        return true;
      } else return false;
    },
  },

  data() {
    return {
      loading: false,
      bulkActionIds: [],
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    let payload = to.params && to.params.status ? to.params.status : "editing";
    this._getOrders(payload)
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
    next();
  },

  mounted() {
    this.loading = true;
    // console.log(this.$route.params.status);
    this._getOrders(this.$route.params.status || "editing")
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },

  methods: {
    ...mapActions("orders", ["_getOrders", "_navigate"]),

    bulkAction(action) {
      if (action == "pay") {
        console.log("bulkActionIds", this.bulkActionIds);
        this.$router.push({
          name: "BulkCheckout",
          params: {
            ids: JSON.stringify(this.bulkActionIds),
          },
        });
      }
    },
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.is-title {
  max-height: 3px !important;
  overflow-y: hidden;
  line-height: 1.5;
  overflow: hidden;
}
td {
  padding: 2px !important;
}
</style>
